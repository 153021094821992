<template>
  <div class="MemoBot">
    Loading your team...
  </div>
</template>

<script>
import gameHelper from '@/modules/games-shared/game-helper';
import settings from '@/settings';

export default {
    data() {
        return {};
    },
    mounted() {
        fetch(settings.endpoints.memobot + '/members/')
            .then(response => response.json())
            .then(data => {
                console.log(data);
                const items = data.data.map(({ first_name, last_name, picture }) => {
                    // eslint-disable-line camelcase
                    return {
                        name: first_name, // eslint-disable-line camelcase
                        surname: last_name, // eslint-disable-line camelcase
                        image: picture.data.url,
                        gender: 'M',
                        country: 'Western'
                    };
                });
                const settings = {
                    numberOfItems: items.length,
                    includeName: true,
                    includeSurname: true,
                    items,
                    itemMemorizeTimeLimit: 1000,
                    itemQuizTimeLimit: 1000
                };
                console.log(settings);
                this.startCustomSession('faces', settings);
            });
    },
    methods: {
        startCustomSession(gameId, settings) {
            gameHelper.createPracticeSession({
                gameId,
                exitUrl: '/home',
                sessionOptions: settings,
                navigate: true
            });
        }
    }
};
</script>

<style scoped lang="scss">
.GamesView {
    padding: 1em;
    background-color: black;
    color: white;
    overflow-y: auto;
}
.Game {
    margin: 1em auto;
    // padding: 1em;
    border: 1px solid black;
    background-color: rgba(white, 0.2);
    max-width: 30em;
}
.Game__header {
    text-align: center;
    border-bottom: 1px solid rgba(black, 0.1);
    padding: 1em;
}
.Game__body {
    padding: 1em;
}

.Game__standardEntries {
    display: flex;
    flex-wrap: wrap;

    .Button {
        margin: 0.2em;
    }
    .Button:not(:first-child) {
        // margin-left: 1em;
    }
}

.Button {
    // border: 1px solid rgba(white, 0.1);
    background-color: white;
    color: black;
    border-radius: 2em;
    padding: 0.3em 1em;
}

.Custom {
    position: relative;
    padding: 1em;
    background-color: rgba(white, 0.1);
    margin: 1em;
}
.Custom__label {
    //
}
.Custom__json {
    white-space: pre;
}
.Custom__toggleJsonButton {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1em;
    color: yellow;
}
</style>
